<template>
  <div style="width: 20%;display: flex;flex-direction: column;margin-right:1%;margin-bottom:15px;border: 1px solid #ccc;border-radius: 10px;">
    <div style="display: flex;align-items:center;justify-content: space-between;padding:15px 15px 30px 15px;border-bottom: 1px solid #ccc">
      <div style="display: flex;align-items:center;">
        <div style="font-size: 18px;font-weight: bold;margin-right: 15px">
          {{segment.name}}
        </div>
      </div>
      <div style="display: flex;justify-content: space-evenly;align-items: center">
        <div>
          <v-switch color="#6ab64a" hide-details style="padding: 0;margin: 0" :value="segment.active" @change="toggleActiveSwitch(segment)" v-model="segment.active"></v-switch>
        </div>
        <v-menu offset-y open-on-hover bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                dark
                small
                icon
                v-bind="attrs"
                v-on="on"
            >
              <v-icon size="30">mdi-cog</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item link @click="$emit('editSegment',segment)">
              <v-list-item-title>Edit</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="toggleActive(segment)">
              <v-list-item-title>{{segment.active?'Disable':'Active'}}</v-list-item-title>
            </v-list-item>
              <v-list-item link>
              <v-list-item-title @click="$emit('deleteSegment',segment)" style="color:red;">Delete</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <div style="padding: 15px;">
      <div style="display:flex;padding:5px;border-radius: 5px;font-size:12px;">
        {{segment.small_description}}
      </div>
    </div>
  </div>
</template>

<script>
  import {mapActions} from 'vuex'
  export default {
    name: "GuestSegment",
    props:['segment'],
    methods:{
      ...mapActions('segments',['putSegment']),
      async toggleActive(segment){
        if(segment.active){
          segment.active = 0;
        }else{
          segment.active = 1;
        }
        await this.putSegment(segment);
      },
      async toggleActiveSwitch(segment){
        if(segment.active){
          segment.active = 1;
        }else{
          segment.active = 0;
        }
        await this.putSegment(segment);
      },
    }
  }
</script>

<style scoped>

</style>
