<template>
    <v-dialog :fullscreen="$store.state.isMobile" max-width="600px"
        v-model="dialog" @click:outside="close"
    >
        <div class="popup__content">
            <v-btn class="popup__close" small icon @click="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <h5 class="popup__title">{{title}}</h5>
            <div class="popup__actions">
                <v-btn class="popup__action btn btn_primary"
                       color="primary"
                       depressed
                       @click="close"
                >
                  {{$t('app["Отмена"]')}}
                </v-btn>
                <v-btn class="popup__action btn btn_primary"
                    color="primary"
                    depressed
                    @click="confirm"
                >
                    {{$t('app["Удалить"]')}}
                </v-btn>
            </div>
        </div>
    </v-dialog>
</template>

<script>
export default {
    props: {
        title: String
    },
    data: () => ({
        dialog: false,
    }),
    methods: {
        open() {
            this.dialog = true;
        },
        close() {
          this.$store.state.deleteMenuItemTrigger = false;
            this.dialog = false;
        },
        confirm() {
            this.$emit('confirm', true);
            this.close();
        }
    }
}
</script>

<style lang="scss" scoped>
.popup__title {
    margin-bottom: 50px;
    padding: 0 40px;
}
.popup__actions {
    display: flex;
    margin: 0 -10px;
}
.popup__action {
    width: calc(50% - 20px);
    margin: 0 10px;
}
</style>
